/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fbfcff;
  // overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #555555;
  background-color: #fbfcff;
}
.wrap {
  padding: 30px 0;
}
.container-fluid.but-fixed {
  max-width: 1356px;
}
.footer {
  padding-top: 20px;
  padding-bottom: 15px;
  width: 100%;
  background-color: #f5f5f5;
  color: #555;
  border-top: 1px solid #e4e4e4;
  font-size: 0.9rem;
  p {
    margin: 0 0 5px 0;
  }
  a {
    color: #555;
  }
  .social a {
    border: 0;
  }
  img {
    display: inline-block;
  }
}

/* --------- asc styles --------- */
#header {
  //background: rgba(255, 255, 255, 0.8);
  a.logo {
    float: left;
    border: 0;
    width: 75%;
    max-width: 300px;
    img {
      margin-right: 5px;
      width: auto;
      height: auto;
      max-width: 290px;
    }
  }
  #support {
    p {
      color: #fff;
      margin: 2px 12px 0 0;
      strong {
        font-size: 16px;
      }
    }
    a.login {
      padding: 0 12px 2px 12px;
      color: #fff;
      background: #ac0123;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e828';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        background: darken(#ac0123, 7.5%);
      }
    }
    span.phone {
      padding-right: 8px;
      border-right: 1px dotted #fff;
    }
    a.livechat {
      color: #fff;
      margin: 0 0 0 10px;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e830';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
    }
  }
}

#header-wrap {
  background-color: #5e7595;
}

#banner-wrap {
  background-color: #5e7595;
  color: #fff;
  h1,
  h2,
  h3 {
    text-shadow: 0 1px 0 #000;
  }
  ul:not(.drop) {
    padding: 1.3rem 1.7rem;
    background-color: rgba($color: #000000, $alpha: 0.2);
    border-radius: 3px;
    font-size: 1.2em;
  }
  .seal {
    height: auto;
    max-width: 110px;
  }
}

// custom dropdown CTA styles
.cta-dropdown {
  width: 220px;
  vertical-align: middle;
  display: inline-block;
  span.dropdown {
    padding: 16px;
    padding-bottom: 14px;
    color: #333;
    font-weight: 400;
    background: $cta-yellow !important;
    border: 0;
    height: auto;
    line-height: 1;
    font-size: 21px;
    text-align: center;
    font-family: 'Fjalla One', 'Open Sans', 'Trebuchet MS', Verdana, Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    & + .drop {
      top: 50px;
      width: 220px !important;
      li a {
        color: #333 !important;
      }
    }
    &:after {
      display: none;
    }
    &:hover {
      background: lighten($cta-yellow, 7) !important;
    }
  }
}
/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  // this adds an extra padding to fluid containers
  [class*='container-'] {
    padding-right: var(--bs-gutter-x, 1.5rem);
    padding-left: var(--bs-gutter-x, 1.5rem);
  }
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header a.logo {
    img {
      margin-right: 15px;
    }
  }
}
// roughtly 900px/16px
@media only screen and (min-width: 56.25em) {
  #header-wrap {
    border-top: 3px solid #ac0123;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  // this reverts the extra padding added to fluid containers
  [class*='container-'] {
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
  }
  #header a.logo {
    width: 50%;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  #banner {
    background-image: url(../images/banner-bg.png);
    background-position: 95% 50%;
    background-repeat: no-repeat;
  }
}
